import React from "react";
import Achievements from "./Achievements";
import PersonalInfo from "./PersonalInfo";
import { useTranslation } from 'react-i18next';
import '../../i18n'; // Importa la configuración de i18n

const Index = () => {
  const { t } = useTranslation();
  return (
    <section className="main-content ">
      <div className="container">
        <div className="row">
          {/* Personal Info Starts */}

          <div className="col-xl-6 col-lg-5 col-12">
            <div className="row">
              <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                  {t('personal_info')}
                </h3>
              </div>
              {/* End .col */}

              <div className="col-12 d-block d-sm-none">
                <img
                    src="img/hero/img-mobile.jpg"
                    className="img-fluid main-img-mobile"
                    alt="about avatar"
                />
              </div>
              {/* image for mobile menu */}

              <div className="col-12">
                <PersonalInfo/>
              </div>
              {/* End personal info */}

              <div className="col-12 mt-1">
                <a className="button" href="Ezequiel Barnes - CV.pdf" download>
                  <span className="button-text">{t('download_cv')}</span>
                  <span className="button-icon fa fa-download"></span>
                </a>
              </div>
              {/* End download button */}
            </div>
          </div>
          {/*  Personal Info Ends */}

          {/*  Boxes Starts */}
          <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
            <Achievements/>
          </div>
          {/* Achievements Ends */}
        </div>
        {/* End .row */}
      </div>
    </section>
  );
};

export default Index;
