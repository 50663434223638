import React from "react";

const educationContent = [
  {
    year: "2014",
    degree: "COMPUTER ENGINEERING",
    institute: "UNIVERSIDAD NACIONAL DE LA MATANZA",
    details: `  University Technician in Software Development with 10 pending subjects for the Computer Engineering degree`,
  },
  {
    year: "2010",
    degree: "COMPUTER TECHNICIAN",
    institute: "Instituto Padre Elizalde",
    details: `High school degree`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
