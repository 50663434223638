import React from "react";

const experienceContent = [
  {
    year: "   2015 - Present",
    position: " Founder",
    compnayName: "Evolve Software & Design",
    details: `  Java + AWS high-concurrency application development (L2J) - Growth Hacking & Software - https://www.evolve-sdf.com`,
  },
  {
    year: "2016 - 2018",
    position: "Senior Project Leader",
    compnayName: "Emprendimientos Satelitales",
    details: `Core dev & Project Leader - Sistema de Turnos Online Verificación Técnica Vehicular de Capital Federal (SUVTV CABA)`,
  },
  {
    year: "2015 - 2016",
    position: "Software Development Engineer",
    compnayName: "Minder S.A.",
    details: `RecibosElectronicos.com - Ssr Software Development Engineer. Oracle E-Business Suite ERP [PL/SQL - Oracle Forms - Oracle Reports] - Senior analyst`,
  },
  {
    year: "2013 - 2015",
    position: "Software Development",
    compnayName: "Federación Argentina de Deportes para Ciegos (FADEA)",
    details: `Argentina Blind Sports Federation website Developer`,
  },
  {
    year: "2011 - 2013",
    position: "Software Development",
    compnayName: "Empiria IT",
    details: `C#.NET - Android apps Jr dev`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
