import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/Hero";
import Index from "../../components/about";
import Address from "../../components/Address";
import Contact from "../../components/Contact";
import Social from "../../components/Social";
import Experience from "../../components/about/Experience";
import Skills from "../../components/about/Skills";
import Education from "../../components/about/Education";
import LanguageSelector from "../../components/LanguageSelector";
import { useTranslation } from 'react-i18next';

const HomeDark = () => {
  const { t } = useTranslation();
  const menuItem = [
    { icon: "fa-home", menuName: t('home') },
    { icon: "fa-user", menuName: t('about') },
    { icon: "fa-briefcase", menuName: t('carreer') },
    { icon: "fa-envelope-open", menuName: t('contact') },
  ];
  return (
    <div className="yellow">
      <Tabs>
        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
            <LanguageSelector/>
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Hero Content Starts */}
          <TabPanel className="home ">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              <Hero />
            </div>
          </TabPanel>
          {/* Hero Content Ends */}

          {/* About Content Starts */}
          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center mt-5">
                <h1>
                  {t('about')} <span>{t('personal')}</span>
                </h1>
                <span className="title-bg">Resume</span>
              </div>
              {/* End title */}
              <Index />
            </div>
          </TabPanel>
          {/* About Content Ends */}
          {/* Skills Content Starts */}
          <TabPanel className="carrer about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  {t('carreer')} <span>{t('skills')}</span>
                </h1>
                <span className="title-bg">{t('resume')}</span>
              </div>
              {/* End title */}
              <div className="container">
                {/* Experience & Education Starts */}
                <div className="row">
                  <div className="col-12">
                    <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                      {t('experience')} <span>&</span> {t('education')}
                    </h3>
                  </div>
                  <div className="col-lg-6 m-15px-tb">
                    <div className="resume-box">
                      <Experience/>
                    </div>
                  </div>
                  <div className="col-lg-6 m-15px-tb">
                    <div className="resume-box">
                      <Education/>
                    </div>
                  </div>
                </div>
                {/*  Experience & Education Ends */}

                <hr className="separator mt-1"/>

                {/* Skills Starts */}
                <div className="row">
                  <div className="col-12">
                    <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
                      {t('my_skills')}
                    </h3>
                  </div>
                  <Skills/>
                </div>
                {/* Skills Ends */}
              </div>
            </div>
          </TabPanel>
          {/* Skills Content Ends */}
          {/* Portfolio Content Starts */}
          {/*<TabPanel className="portfolio professional">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>portfolio</span>
              </h1>
              <span className="title-bg">works</span>
            </div>
             End title
            <div
              className="container grid-gallery main-content"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <Portfolio />
            </div>
             End grid gallery
          </TabPanel>*/}
          {/* Portfolio Content Ends */}

          {/* Contact Content Starts */}
          <TabPanel className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                {t('get_in_touch1')} <span>{t('get_in_touch2')}</span>
              </h1>
              <span className="title-bg">{t('contact')}</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-lg-4">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                    {t('lets_work_together')}
                  </h3>
                  <p className="open-sans-font mb-4">
                    {t('feel_free_to_contact_me')}
                  </p>
                  <Address />
                  {/* End Address */}

                  <Social />
                  {/* End Social */}
                </div>
                {/* Left Side Ends */}

                {/*  Contact Form Starts  */}
                <div className="col-12 col-lg-8">
                  {/*<Contact />*/}
                  <img src="/img/contact.png"/>
                </div>
                {/*  Contact Form Ends */}
              </div>
            </div>
            {/* End .container */}
          </TabPanel>
          {/* Contact Content Ends */}

          {/* Blog Content Starts */}
         {/* <TabPanel className="blog">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>blog</span>
              </h1>
              <span className="title-bg">posts</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
                Articles Starts
              <div className="row pb-50">
                <Blog />
              </div>
               Articles Ends
            </div>
          </TabPanel>*/}
          {/* Blog Content Ends */}
        </div>
      </Tabs>
    </div>
  );
};

export default HomeDark;
