import React from "react";
import { useTranslation } from 'react-i18next';
import '../../i18n'; // Importa la configuración de i18n



const Achievements = () => {
  const { t } = useTranslation();
  const achievementsContent = [
    { title: "12", subTitle1: t('years_of'), subTitle2: t('experience') },
    { title: "50", subTitle1: t('finished_projects1'), subTitle2: t('finished_projects2') },
    { title: "1M", subTitle1: t('happy_users1'), subTitle2: t('happy_users2') },
    { title: "5000", subTitle1: t('coffee_drunk1'), subTitle2: t('coffee_drunk2') },
  ];
  return (
    <div className="row">
      {achievementsContent.map((val, i) => (
        <div className="col-6" key={i}>
          <div className="box-stats with-margin">
            <h3 className="poppins-font position-relative">{val.title}</h3>
            <p className="open-sans-font m-0 position-relative text-uppercase">
              {val.subTitle1} <span className="d-block">{val.subTitle2}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
